import gql from 'graphql-tag'

export const isInUserFavorites = gql`
  query isInUserFavorites($objectId: ID!) {
    isInUserFavorites(objectId: $objectId)
  }
`

export const mutateFavorite = gql`
  mutation favorite($objectId: ID!) {
    favorite(input: { objectId: $objectId }) {
      deleted
      created
      clientMutationId
    }
  }
`
