import { useSelector } from '~/reducers'
import { DocumentNode } from 'graphql'
import { OperationVariables } from 'apollo-client'
import { useQuery, QueryHookOptions } from '@apollo/react-hooks'

function useCurrentUserQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables>
) {
  const token = useSelector((state) => state.auth.token)
  const qs = useQuery<TData, TVariables>(query, {
    ...options,
    ssr: false,
    skip: !token,
  })
  return qs
}

export default useCurrentUserQuery
