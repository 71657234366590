import React from 'react'
import { fontPrimary, fontSecondary } from '~/theme'
import clsx from 'clsx'
import {
  withStyles,
  createStyles,
  WithStyles,
  Theme,
} from '@material-ui/core/styles'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      fontFamily: fontPrimary,
      color: theme.palette.text.primary,
      fontWeight: 400,
      margin: 0,
      padding: 0,
      fontSize: '1rem',
    },
    storyHeadline: {
      fontFamily: fontSecondary,
      fontSize: '2rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '2rem',
      },
      fontWeight: 800,
      letterSpacing: '.05em',
      lineHeight: '1.2em',
    },
    entityHeadline: {
      fontFamily: fontSecondary,
      fontSize: '2.2rem',
      fontWeight: 600,
    },
    subheading: {
      fontSize: '1rem',
    },
    sommaireTitle: {
      fontFamily: fontSecondary,
      fontWeight: 700,
      letterSpacing: '0.01em',
      fontSize: '1.2rem',
      padding: '5px 0',
      '&$active': {
        color: theme.palette.primary.main,
      },
    },
    sectionStoryTitle: {
      fontFamily: fontSecondary,
      fontSize: '2rem',
      fontWeight: 600,
      paddingBottom: 10,
    },
    sectionEntityTitle: {
      fontFamily: fontSecondary,
      fontSize: '1.7rem',
      fontWeight: 400,
    },
    itemStoryTitle: {
      fontFamily: fontSecondary,
      fontWeight: 800,
      fontSize: '1.6rem',
      lineHeight: '1.2em',
      letterSpacing: '.01em',
      paddingRight: 50,
      marginTop: 20,
      marginBottom: 5,
    },
    itemEntityTitle: {
      fontFamily: fontSecondary,
      fontWeight: 600,
      fontSize: '1.2rem',
      lineHeight: '1.2em',
    },
    itemPostTitle: {
      fontFamily: fontPrimary,
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: '1.2em',
    },
    postName: {
      fontFamily: fontPrimary,
      fontWeight: 600,
      fontSize: '1.1rem',
      lineHeight: '1.2em',
      marginBottom: 30,
      marginTop: 20,
    },
    styleName: {
      fontSize: '1.2rem',
      fontWeight: 700,
      fontStyle: 'italic',
      color: '#f392c6',
      fontFamily: fontSecondary,
    },
    quote: {
      fontFamily: fontSecondary,
      position: 'relative',
      fontSize: '2rem',
      marginTop: 20,
      maxWidth: 800,
      marginBottom: 20,
      padding: 40,
      whiteSpace: 'pre-line',
      display: 'block',
      quotes: '"“" "”" "‘" "’"',
      '&:before': {
        color: '#ccc',
        content: 'open-quote',
        fontSize: '4em',
        position: 'absolute',
        left: -13,
        top: 10,
      },
      '&:after': {
        content: 'no-close-quote',
      },
      '& span': {
        display: 'block',
        color: '#333333',
        fontStyle: 'normal',
        fontWeight: 'bold',
        marginTop: '1em',
        fontSize: '1.2rem',
      },
    },
    eventDate: {
      fontFamily: fontSecondary,
      fontWeight: 700,
      textTransform: 'uppercase',
      color: 'inherit',
    },
    eventTime: {
      fontFamily: fontSecondary,
      fontWeight: 700,
      color: 'inherit',
    },
    active: {},
  })

interface Props extends WithStyles<typeof styles> {
  className?: string
  style?: React.CSSProperties
  active?: boolean
  variant:
    | 'storyHeadline'
    | 'entityHeadline'
    | 'subheading'
    | 'sommaireTitle'
    | 'sectionStoryTitle'
    | 'sectionEntityTitle'
    | 'itemStoryTitle'
    | 'itemEntityTitle'
    | 'itemPostTitle'
    | 'quote'
    | 'postName'
    | 'styleName'
    | 'eventDate'
    | 'eventTime'
}

const headlineMapping = {
  storyHeadline: 'h1',
  entityHeadline: 'h1',
  subheading: 'h2',
  sommaireTitle: 'h3',
  sectionStoryTitle: 'h2',
  sectionEntityTitle: 'h2',
  itemStoryTitle: 'h3',
  itemEntityTitle: 'h3',
  itemPostTitle: 'h3',
  quote: 'q',
  postName: 'h3',
  styleName: 'h3',
  eventDate: 'span',
  eventTime: 'span',
}

const Typo = ({ variant, classes, active, className, ...props }: Props) => {
  const Component: any = headlineMapping[variant]
  return (
    <Component
      className={clsx(
        classes.root,
        classes[variant],
        { [classes.active]: active },
        className
      )}
      {...props}
    />
  )
}

export default withStyles(styles)(Typo)
