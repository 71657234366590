import React from 'react'
import Fab from '@material-ui/core/Fab'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import {
  isInUserFavorites as isInUserFavoritesQs,
  mutateFavorite,
} from './query'
import BookmarkIcon from '@material-ui/icons/Bookmark'
import EmptyBookmarkIcon from '@material-ui/icons/BookmarkBorder'
import { useSelector } from '~/reducers'
import Router from '~/utils/router'
import Tooltip from '@material-ui/core/Tooltip'
import { useMutation } from '@apollo/react-hooks'
import {
  isInUserFavorites as isInUserFavoritesTs,
  favorite,
  favoriteVariables,
  isInUserFavoritesVariables,
} from '~/queries'
import useCurrentUserQuery from '~/hooks/useCurrentUserQuery'

interface Props {
  objectId: string
  follow?: boolean
  star?: boolean
  iconButton?: boolean
  button?: boolean
  className?: string
}

const FavoriteButton = ({
  objectId,
  follow,
  star,
  iconButton,
  button,
  className,
  ...props
}: Props) => {
  const isAuth = useSelector((state) => !!state.auth.token)
  const { data } = useCurrentUserQuery<
    isInUserFavoritesTs,
    isInUserFavoritesVariables
  >(isInUserFavoritesQs, { variables: { objectId } })
  const [toggleFavorite] = useMutation<favorite, favoriteVariables>(
    mutateFavorite,
    {
      refetchQueries: [{ query: isInUserFavoritesQs, variables: { objectId } }],
    }
  )

  let isFav, isNotFav, color: 'default' | 'primary' | undefined
  if (follow) {
    isFav = 'Following'
    isNotFav = 'Follow'
    color = data?.isInUserFavorites ? 'default' : 'primary'
  } else if (star) {
    isFav = <BookmarkIcon />
    isNotFav = <EmptyBookmarkIcon />
  }
  function handleClick() {
    if (isAuth) {
      toggleFavorite({ variables: { objectId } })
    } else {
      Router.push('/login')
    }
  }
  const body = data?.isInUserFavorites ? isFav : isNotFav
  const renderButton = () => {
    if (button) {
      return (
        <Button onClick={handleClick} className={className} {...props}>
          {body}
        </Button>
      )
    }
    if (iconButton) {
      return (
        <IconButton onClick={handleClick} className={className} {...props}>
          {body}
        </IconButton>
      )
    }
    if (body) {
      return (
        <Fab
          onClick={handleClick}
          className={className}
          color={color}
          {...props}
        >
          {body}
        </Fab>
      )
    }
    return <div>bug</div>
  }
  return <Tooltip title="Ajouter/enlever des favoris">{renderButton()}</Tooltip>
}

export default FavoriteButton
